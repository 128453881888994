import { COUNTRY_CODES } from "utilities/countryCodes";

const PERMANENT_MOVE_TYPES = ['PERMASSIGNLOCAL', 'DOMPERMTRANS', 'CAND', 'INTLPERMTRANS', 'PERMDOMINTL'];

export const isProposedEndDateRequired = (moveType) => {
  // require end date if the moveType is specified, and not permanent
  return moveType && moveType !== '' && !PERMANENT_MOVE_TYPES.includes(moveType);
};

export const removeLettersFromBusCode = (busCode) => {
  let updatedBusCode = "";
    for (const char of busCode) {
        if (!(char.toUpperCase() !== char.toLowerCase())) {
          updatedBusCode = updatedBusCode + char;
        }
    }
    return updatedBusCode;
};


export const convertPhoneNumber = (phoneNumber, intlCode) => {
  // make sure that phone number still contains a value
  if (!phoneNumber) return '';
  
  // if this, we are in pending view
  if (intlCode === '') return convertInPendingView(phoneNumber);
  
  let intlMatch = COUNTRY_CODES.find((a) => a['alpha-3'] === intlCode );
  
  // if country code somehow doesn't exist, then set it to USA
  if (!intlMatch) intlMatch = COUNTRY_CODES.find((a) => a['alpha-3'] === 'USA');
  
  //remove the dial code and then all non-digits
  phoneNumber = phoneNumber.replace(intlMatch['dial-code'], '');
  phoneNumber = phoneNumber.replace(/\D/g, '');
  
  if (!intlMatch['number-format']) return turnToNumberFormat(phoneNumber, '(xxx) xxx-xxxx');
  
  // if phoneNumber.length > number-format.length, insert as many as fits in the format and return (don't allow any more to be added)
  if (phoneNumber.length === 1) return `${intlMatch['dial-code']} ${phoneNumber}`;
  else if (phoneNumber.length > intlMatch['number-format'].length) return turnToNumberFormat(phoneNumber, intlMatch['number-format']);  
  else return turnToNumberFormat(phoneNumber, intlMatch['number-format']);
};

export const turnToNumberFormat = (phoneNumber, numberFormat) => {
  // for every character in number-format, replace an 'x' with it
  // if current index == x, insert the number there, else break and continue
  // the validation will take care of any not allowed characters or not allowed lengths
  let j = 0;
  let formattedNumber = '';
  for (let i = 0; i < numberFormat.length; i++)
  {
    // sometimes we are off by 1 which messes up the entire formatting, need a case for:
    // if we are at the last number in the phone number and our current index is not an 'x', then we need to find the next instance of 'x' and assign it there
    if (numberFormat[i] === 'x' && phoneNumber[j])
    {
      formattedNumber = formattedNumber + phoneNumber[j];
      j ++;
    }
    else
    {
      formattedNumber = formattedNumber + numberFormat[i];
    }
  }
  if (formattedNumber.includes('x'))
  {
    formattedNumber = formattedNumber.substring(0, formattedNumber.indexOf('x'));
    if (formattedNumber.substring(formattedNumber.length - 2) === ') ') {
      return formattedNumber.substring(0, formattedNumber.length - 2);
    } else if (formattedNumber.indexOf(' ') === formattedNumber.length - 1) {
      return formattedNumber;
    } else if (formattedNumber[formattedNumber.length - 1] === '-' || formattedNumber[formattedNumber.length - 1] === ' ') {
      return formattedNumber.substring(0, formattedNumber.length - 1);
    } else {
      return formattedNumber;
    }
  }
  else
  {
    return formattedNumber;
  }
};

export const convertInPendingView = (phoneNumber) => {
    
  //MoveTrack mortgage leads can't handle +1 so we're saving them as (111) 222-3333 so if the number
  //doesn't start with a +, assume it's a +1 number
  if(!phoneNumber.startsWith('+')) phoneNumber = '+1 '.concat(phoneNumber);

  // find the first space in phonenumber
  let intlDialCode = getIntlDialCodeFromNumber(phoneNumber);  

  let intlMatch;
  if(intlDialCode === '+1') intlMatch = getCountryByAreaCode(phoneNumber);
  else intlMatch = COUNTRY_CODES.find((a) => a['dial-code'] === intlDialCode );
  
  if(!intlMatch) intlMatch = COUNTRY_CODES.find((a) => a['alpha-3'] === 'USA' );
 
  //remove the dial code and then all non-digits
  phoneNumber = phoneNumber.replace(intlMatch['dial-code'], '');
  phoneNumber = phoneNumber.replace(/\D/g, '');

  let result = turnToNumberFormat(phoneNumber, intlMatch['number-format']);

  return result;
};

  //Returns a international alpha-3 (USA, CAN, AUS, etc) for a phone number.
  //Phone number parts must be separated by a space 
  //Phone number must start with a +. If it doesn't, a empty string is returned
  export const getCountryPhoneDialCode = (phoneNumber) => {
      let intlDialCode = getIntlDialCodeFromNumber(phoneNumber);
  
  let intlMatch;
  if(intlDialCode === '+1') intlMatch = getCountryByAreaCode(phoneNumber);
  else intlMatch = COUNTRY_CODES.find((a) => a['dial-code'] === intlDialCode );
  if (intlMatch) return intlMatch['alpha-3'];
  else return 'USA';
  }

  //Gets the area code for a +1 number
function getCountryByAreaCode(phoneNumber){
  //get the area code VIA RegEx
  let areaCodeMatch = phoneNumber.match(/\(([^)]+)\)/);
  let areaCode;

  //if no match, try to get the first set of numbers after +1
  if(!areaCodeMatch){
    phoneNumber = phoneNumber.replace('+1', '').trim();
    areaCode = phoneNumber.substring(0, phoneNumber.indexOf(' '));
  }   
  else areaCode = areaCodeMatch[1];
  let match;

  //if areaCode is still null or empty, there is something wrong with the number so get the country USA
  if(!areaCode || areaCode === '') match = COUNTRY_CODES.find((a) => a['alpha-3'] === 'USA');
  else match = COUNTRY_CODES.find((a) => a['area-codes'] !== undefined && a['area-codes'].includes(areaCode));

  return match;
}


function getIntlDialCodeFromNumber(phoneNumber){
  if(!phoneNumber.startsWith("+")) return '+1';
  
  let intlDialCode = phoneNumber.split(' ')[0];

  if(!intlDialCode) intlDialCode = phoneNumber;
  intlDialCode = intlDialCode.replace(/\(/g, '');
  intlDialCode = intlDialCode.replace(/\)/g, '');

  return intlDialCode;
}